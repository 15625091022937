import {
  series,
  expert,
  forum,
  partner,
  community,
  knowledgeCategory,
  video,
} from "../../js/path";
import _ from 'lodash';

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      oldForm: {},
      series_id: null,
      table_header: [],
      fields: [{
          key: "id",
          sortable: true,
        },
        {
          key: "title",
          label: 'Series Title'
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      params: "",
      tableData: [],
      activeTab: "all",
      key: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      forum: [],
      partner: [],
      community: [],
      expert: [],
      knowledgeCategory: [],
      videos: [],
      form: {
        title: "",
        description: "",
        meta_title: "",
        meta_desc: "",
        meta_keywords: "",
        community_selected: [],
        partner_id: "",
        partner_division_id: "",
        expert_selected: [],
        knowledge_categories: [],
        video_selected: [],
        survey_fail: 0,
      },
      image_name_url: "",
      image_name: "",
      edit: {
        image_name_url: null,
      },
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData(this.activeTab);
      else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    addTag(newTag) {
      this.form.community_selected.push(newTag);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchCommunity(query) {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + '&search=' + query);
      if (data.status) {
        this.community = data.response.data;
      }
    },
    async fetchSingleCommunity(id) {
      const url = community.fetchCommunity;
      const data = await this.getRequest(url + '/' + id);
      if (data.status) {
        this.form.community_selected.map(data.response);
      }
    },
    async fetchExpert(query) {
      const url = expert.expertUrl;
      const data = await this.getRequest(url + '?search=' + query);
      if (data.status) {
        this.expert = data.response.data;
      }
    },
    async fetchSingleExpert(id) {
      const url = expert.expertUrl;
      const data = await this.getRequest(url + '/' + id);
      if (data.status) {
        this.form.expert_selected.push(data.response);
      }
    },
    async fetchPartner(query) {
      const url = partner.partnerUrl;
      const data = await this.getRequest(url + '?search=' + query);
      if (data.status) {
        this.partner = data.response.data;
      }
    },
    async fetchSinglePartner(id) {
      const url = partner.partnerUrl;
      const data = await this.getRequest(url + '/' + id);
      if (data.status) {
        this.form.partner_id = data.response;
      }
    },
    async fetchForum(query) {
      const url = forum.fetchAllActiveForum;
      const data = await this.getRequest(url + '&search=' + query);
      if (data.status) {
        this.forum = data.response.data;
      }
    },
    async fetchSingleForum(id) {
      const url = forum.forumUrl;
      const data = await this.getRequest(url + '/' + id);
      if (data.status) {
        this.form.partner_division_id = data.response;
      }
    },
    async fetchVideo(query) {
      const url = video.videoUrl;
      const data = await this.getRequest(url + '?search=' + query);
      if (data.status) {
        this.videos = data.response.data;
      }
    },
    async fetchSingleVideo(id) {
      const url = video.videoUrl;
      const data = await this.getRequest(url + '/' + id);
      if (data.status) {
        this.form.video_selected.push(data.response);
      }
    },
    async fetchKnowledgeCategory() {
      const url = knowledgeCategory.fetchAllActiveKnowledgeCategory;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response.data;
        this.knowledgeCategory = responseData;
      }
      if (this.$route.name == "edit-series") {
        let knowledgeCat = [];

        if (this.form.knowledge_categories != "") {
          this.form.knowledge_categories.map((cat) => {
            knowledgeCat.push(cat.knowledge_category_id);
          });

          this.form.knowledge_categories = this.knowledgeCategory.filter(
            (item) => {
              if (knowledgeCat.includes(item.id)) {
                return item;
              }
            }
          );
        }
      }
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = series.seriesUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = series.seriesUrl + "?filter=" + txt;
        }
      } else if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = series.seriesUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = series.seriesUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = series.seriesUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = series.seriesUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.getUrl();
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async fetchAllCommunity() {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url);
      if (data.status) {
        this.form.community_selected = data.response.data;
      }
    },
    async fetchSeries(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = series.seriesUrl + "/" + id;
        this.$store.commit("loader/updateStatus", true);
        const data = await this.getRequest(url);
        this.$store.commit("loader/updateStatus", false);

        if (data.status) {
          const responseData = data.response;

          if (responseData.title != "" && responseData.title != null) {
            this.form.title = responseData.title;
          }

          if (responseData.description != "" && responseData.description != null) {
            this.form.description = responseData.description;
          }

          if (responseData.meta_title != "" && responseData.meta_title != null) {
            this.form.meta_title = responseData.meta_title;
          }

          if (responseData.meta_desc != "" && responseData.meta_desc != null) {
            this.form.meta_desc = responseData.meta_desc;
          }

          if (responseData.meta_keywords != "" && responseData.meta_keywords != null) {
            this.form.meta_keywords = responseData.meta_keywords;
          }

          if (responseData.survey_fail) {
            this.form.survey_fail = true;
          }

          if (responseData.partner_id != "" && responseData.partner_id != null) {
            this.form.partner_id = this.fetchSinglePartner(responseData.partner_id);
          }

          if (responseData.partner_division_id != "" && responseData.partner_division_id != null) {
            this.form.partner_division_id = this.fetchSingleForum(responseData.partner_division_id);
          }

          if (responseData.community != null && responseData.community != "") {
            this.form.community_selected = responseData.community.map(c => {
              return {
                id: c.community_id,
                title: c.name
              }
            });
          }

          if (responseData.expert != null && responseData.expert != "") {
            responseData.expert.map((item) => {
              this.fetchSingleExpert(item.expert_id);
            })
          }


          this.form.knowledge_categories = responseData.knowledge_category;
          if (responseData.series_item != null && responseData.series_item != "") {
            responseData.series_item.map((item) => {
              this.fetchSingleVideo(item.video_id);
            })
          }

          this.edit.image_name_url = responseData.image_name;

          this.series_id = responseData.id;
          this.oldForm = _.clone(this.form);

        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    readFile(e, txt) {
      if (txt == "image_name") {
        this.image_name = e.target.files[0];
        if (this.image_name.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB"
          });
          this.$refs.image_name.reset();
          this.image_name_url = '';
          this.edit.image_name_url = '';
          this.image_name = '';
          return false;
        } else {
          this.image_name_url = URL.createObjectURL(this.image_name);
          this.edit.image_name_url = '';
        }
        return true;
      }
    },
    async submitData() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = series.seriesUrl;
        if (this.$route.name == "edit-series") {
          url = series.seriesUrl + "/" + this.series_id;
        }
        let dataAppend = new FormData();
        dataAppend.append("image_name", this.image_name);
        for (var key in this.form) {
          if (
            key != "community_selected" &&
            key != "expert_selected" &&
            key != "knowledge_categories" &&
            key != "video_selected" &&
            key != 'partner_id' &&
            key != 'partner_division_id'
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.form.partner_id != '' && this.form.partner_id != null) {
          dataAppend.append('partner_id', this.form.partner_id.id);
        }
        if (this.form.partner_division_id != '' && this.form.partner_division_id != null) {
          dataAppend.append('partner_division_id', this.form.partner_division_id.id);
        }
        this.form.community_selected.map((key) => {
          dataAppend.append("community_selected[]", key.id);
        });
        this.form.expert_selected.map((key) => {
          dataAppend.append("expert_selected[]", key.id);
        });
        this.form.knowledge_categories.map((key) => {
          dataAppend.append("knowledge_categories[]", key.id);
        });
        this.form.video_selected.map((key) => {
          dataAppend.append("video_selected[]", key.id);
        });
        if (this.form.survey_fail) {
          dataAppend.append("survey_fail", 1);
        } else {
          dataAppend.append("survey_fail", 0);
        }
        if (this.$route.name == "edit-series") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/series");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
      if (this.filter != null && this.filter != "") {
        (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
      } else {
        url.searchParams.delete('search');
      }
      if (this.currentPage != null && this.currentPage != "") {
        (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
      } else {
        url.searchParams.set('page', 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = series.statusUpdate;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
          id: id,
        });
        if (data.status) {
          this.tableData.data[index].is_active = !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteSeries(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = series.seriesUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreSeries(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = series.restoreSeries + "/" + id;
        const data = await this.postRequest(url);
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-series")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-series")) {
            this.fields[3] = {
              key: "delete",
            };
          } else {
            this.fields[4] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-series")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[3] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-series")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[4] = {
              key: "delete",
            };
          }
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
    "form.survey_fail"(v) {
      if (v == 1) {
        this.survey_fail = true;
      } else {
        this.survey_fail = false;
      }
    },
    "status"(v) {
      if (v == 1) {
        this.fetchAllCommunity();
      } else {
        if (this.$route.name == 'add-series') {
          this.form.community_selected = [];
        } else if (this.$route.name == 'edit-series') {
          this.form.community_selected = this.oldForm.community_selected;
        }
      }
    },
  },
  created() {
    if (this.$route.name == "add-series" || this.$route.name == "edit-series") {
      if (this.$route.name == "edit-series") {
        this.fetchSeries(this.$route.params.id);
      }
      this.fetchKnowledgeCategory();
    } else {
      this.activeTab = "all";
      if (this.$route.query.filter) {
        this.activeTab = this.$route.query.filter;
      }
      if (this.$route.query.search) {
        this.filter = this.$route.query.search;
      }
      if (this.$route.query.page && this.$route.query.page != 1) {
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.fetchData(this.activeTab);
      }
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};