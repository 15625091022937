var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-series')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"series",attrs:{"color":"#556ee6","enctype":"multipart/form-data","finishButtonText":this.$route.name == 'add-series' ? 'Save Data' : 'Update Data',"back-button-text":"Go Back!","next-button-text":"Go Next!"},on:{"on-complete":_vm.submitData}},[_c('tab-content',{attrs:{"title":"Series Details","icon":"mdi mdi-account-details","before-change":() => _vm.validateFormOne()}},[_c('b-form-group',[_c('label',{attrs:{"for":"title"}},[_vm._v("Series Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.title.$invalid,
                  },attrs:{"id":"title","placeholder":"Enter Series Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Series Title is required. ")]):_vm._e()],1),_c('b-form-group',[_c('label',{attrs:{"for":"de"}},[_vm._v("Tell Us More About The Series "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ckeditor',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.description.$invalid,
                  },attrs:{"editor":_vm.editor},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Thumbnail Image [Upload Max File Size : 2MB]","label-for":"im"}},[_c('b-form-file',{ref:"image_name",attrs:{"id":"im","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'image_name')}}}),(_vm.$route.name == 'edit-series' && _vm.edit.image_name_url)?[_c('img',{attrs:{"src":_vm.edit.image_name_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.image_name_url)?[_c('img',{ref:"image_name_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.image_name_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('tab-content',{attrs:{"title":"Add Tags","icon":"fa fa-tag","before-change":() => _vm.validateFormTwo()}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-2"},[_c('label',{staticClass:"mb-0",attrs:{"for":"comm"}},[_vm._v("Community "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-checkbox',{attrs:{"button":"","button-variant":"info","size":"sm"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[(_vm.status)?[_vm._v("Unselect All")]:[_vm._v("Select All")]],2)],1),_c('multiselect',{class:{
                      'is-invalid':
                        _vm.store && _vm.$v.form.community_selected.$invalid,
                    },attrs:{"id":"comm","options":_vm.community,"multiple":true,"taggable":true,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchCommunity},model:{value:(_vm.form.community_selected),callback:function ($$v) {_vm.$set(_vm.form, "community_selected", $$v)},expression:"form.community_selected"}}),(_vm.store && !_vm.$v.form.community_selected.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Community is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Expert","label-for":"exp"}},[_c('multiselect',{attrs:{"id":"exp","options":_vm.expert,"multiple":true,"track-by":"id","label":"name","placeholder":"Type here to search"},on:{"search-change":_vm.fetchExpert},model:{value:(_vm.form.expert_selected),callback:function ($$v) {_vm.$set(_vm.form, "expert_selected", $$v)},expression:"form.expert_selected"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])])],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"video"}},[_vm._v("Video "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('multiselect',{class:{
                      'is-invalid': _vm.store && _vm.$v.form.video_selected.$invalid,
                    },attrs:{"id":"video","options":_vm.videos,"multiple":true,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchVideo},model:{value:(_vm.form.video_selected),callback:function ($$v) {_vm.$set(_vm.form, "video_selected", $$v)},expression:"form.video_selected"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Type here to search")])]),(_vm.store && !_vm.$v.form.video_selected.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Video is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Knowledge Category","label-for":"know"}},[_c('multiselect',{attrs:{"id":"know","options":_vm.knowledgeCategory,"multiple":true,"track-by":"id","label":"display_name","placeholder":"Select Knowledge Category"},model:{value:(_vm.form.knowledge_categories),callback:function ($$v) {_vm.$set(_vm.form, "knowledge_categories", $$v)},expression:"form.knowledge_categories"}})],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"part1"}},[_vm._v("Partner "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('multiselect',{class:{
                      'is-invalid': _vm.store && _vm.$v.form.partner_id.$invalid,
                    },attrs:{"id":"part1","options":_vm.partner,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchPartner},model:{value:(_vm.form.partner_id),callback:function ($$v) {_vm.$set(_vm.form, "partner_id", $$v)},expression:"form.partner_id"}}),(_vm.store && !_vm.$v.form.partner_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Partner is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"part"}},[_vm._v("Partner Division "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('multiselect',{class:{
                      'is-invalid':
                        _vm.store && _vm.$v.form.partner_division_id.$invalid,
                    },attrs:{"id":"part","options":_vm.forum,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchForum},model:{value:(_vm.form.partner_division_id),callback:function ($$v) {_vm.$set(_vm.form, "partner_division_id", $$v)},expression:"form.partner_division_id"}}),(_vm.store && !_vm.$v.form.partner_division_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Partner Division is required. ")]):_vm._e()],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"label":"Meta Title","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","maxlength":250,"placeholder":"Enter Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_title)?_c('p',{staticClass:"badge position-absolute",class:{
                        'badge-success': _vm.form.meta_title.length !== 250,
                        'badge-danger': _vm.form.meta_title.length === 250,
                      },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_title.length)+" out of 250 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"label":"Meta Keywords","label-for":"key"}},[_c('b-form-input',{attrs:{"maxlength":250,"id":"key","placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_keywords)?_c('p',{staticClass:"badge position-absolute",class:{
                        'badge-success': _vm.form.meta_keywords.length !== 250,
                        'badge-danger': _vm.form.meta_keywords.length === 250,
                      },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_keywords.length)+" out of 250 chars. ")]):_vm._e()])],1)],1),_c('b-form-group',{attrs:{"id":"input-group-20","label":"Meta Description","label-for":"input-20"}},[_c('b-form-textarea',{attrs:{"id":"input-20","placeholder":"Enter Meta Description","rows":"3"},model:{value:(_vm.form.meta_desc),callback:function ($$v) {_vm.$set(_vm.form, "meta_desc", $$v)},expression:"form.meta_desc"}})],1),_c('b-form-group',[_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('b-form-checkbox',{model:{value:(_vm.form.survey_fail),callback:function ($$v) {_vm.$set(_vm.form, "survey_fail", $$v)},expression:"form.survey_fail"}},[_vm._v("If Survey Show Failed ")])],1)])],1)],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }