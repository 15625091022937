<template>
  <Layout>
    <template v-if="this.$route.name == 'add-series'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard
              @on-complete="submitData"
              color="#556ee6"
              enctype="multipart/form-data"
              ref="series"
              :finishButtonText="
                this.$route.name == 'add-series' ? 'Save Data' : 'Update Data'
              "
              back-button-text="Go Back!"
              next-button-text="Go Next!"
            >
              <tab-content
                title="Series Details"
                icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()"
              >
                <b-form-group>
                  <label for="title"
                    >Series Title <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="title"
                    v-model="form.title"
                    placeholder="Enter Series Title"
                    :class="{
                      'is-invalid': submitted && $v.form.title.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.title.required"
                    class="invalid-feedback"
                  >
                    Series Title is required.
                  </div>
                </b-form-group>
                <b-form-group>
                  <label for="de"
                    >Tell Us More About The Series
                    <span style="color: red">*</span></label
                  >
                  <ckeditor
                    v-model="form.description"
                    :editor="editor"
                    :class="{
                      'is-invalid': submitted && $v.form.description.$invalid,
                    }"
                  ></ckeditor>
                  <div
                    v-if="submitted && !$v.form.description.required"
                    class="invalid-feedback"
                  >
                    Description is required.
                  </div>
                </b-form-group>
                <b-form-group
                  label="Thumbnail Image [Upload Max File Size : 2MB]"
                  label-for="im"
                >
                  <b-form-file
                    id="im"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name')"
                    ref="image_name"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-series' && edit.image_name_url"
                  >
                    <img
                      :src="edit.image_name_url"
                      width="128px"
                      height="128px"
                    />
                  </template>
                  <template v-if="image_name_url">
                    <img
                      :src="image_name_url"
                      width="128px"
                      height="128px"
                      ref="image_name_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
              </tab-content>

              <tab-content
                title="Add Tags"
                icon="fa fa-tag"
                :before-change="() => validateFormTwo()"
              >
                <div class="row">
                  <b-form-group class="col-6">
                    <div
                      class="d-flex justify-content-between align-items-center mb-2"
                    >
                      <label for="comm" class="mb-0"
                        >Community <span style="color: red">*</span></label
                      >
                      <b-form-checkbox
                        v-model="status"
                        button
                        button-variant="info"
                        size="sm"
                      >
                        <template v-if="status">Unselect All</template>
                        <template v-else>Select All</template>
                      </b-form-checkbox>
                    </div>
                    <multiselect
                      id="comm"
                      v-model="form.community_selected"
                      :options="community"
                      :multiple="true"
                      :taggable="true"
                      track-by="id"
                      label="title"
                      @search-change="fetchCommunity"
                      placeholder="Type here to search"
                      :class="{
                        'is-invalid':
                          store && $v.form.community_selected.$invalid,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="store && !$v.form.community_selected.required"
                      class="invalid-feedback"
                    >
                      Community is required.
                    </div>
                  </b-form-group>
                  <b-form-group label="Expert" label-for="exp" class="col-6">
                    <multiselect
                      @search-change="fetchExpert"
                      id="exp"
                      v-model="form.expert_selected"
                      :options="expert"
                      :multiple="true"
                      track-by="id"
                      label="name"
                      placeholder="Type here to search"
                    >
                      <span slot="noOptions"> Type here to search </span>
                    </multiselect>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group class="col-6">
                    <label for="video"
                      >Video <span style="color: red">*</span></label
                    >
                    <multiselect
                      @search-change="fetchVideo"
                      id="video"
                      v-model="form.video_selected"
                      :options="videos"
                      :multiple="true"
                      track-by="id"
                      label="title"
                      placeholder="Type here to search"
                      :class="{
                        'is-invalid': store && $v.form.video_selected.$invalid,
                      }"
                    >
                      <span slot="noOptions">Type here to search</span>
                    </multiselect>
                    <div
                      v-if="store && !$v.form.video_selected.required"
                      class="invalid-feedback"
                    >
                      Video is required.
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Knowledge Category"
                    label-for="know"
                    class="col-6"
                  >
                    <multiselect
                      id="know"
                      v-model="form.knowledge_categories"
                      :options="knowledgeCategory"
                      :multiple="true"
                      track-by="id"
                      label="display_name"
                      placeholder="Select Knowledge Category"
                    >
                    </multiselect>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group class="col-6">
                    <label for="part1"
                      >Partner <span style="color: red">*</span></label
                    >
                    <multiselect
                      @search-change="fetchPartner"
                      id="part1"
                      v-model="form.partner_id"
                      :options="partner"
                      :multiple="false"
                      track-by="id"
                      label="title"
                      placeholder="Type here to search"
                      :class="{
                        'is-invalid': store && $v.form.partner_id.$invalid,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="store && !$v.form.partner_id.required"
                      class="invalid-feedback"
                    >
                      Partner is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="part"
                      >Partner Division <span style="color: red">*</span></label
                    >
                    <multiselect
                      @search-change="fetchForum"
                      id="part"
                      v-model="form.partner_division_id"
                      :options="forum"
                      :multiple="false"
                      track-by="id"
                      label="title"
                      placeholder="Type here to search"
                      :class="{
                        'is-invalid':
                          store && $v.form.partner_division_id.$invalid,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="store && !$v.form.partner_division_id.required"
                      class="invalid-feedback"
                    >
                      Partner Division is required.
                    </div>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group
                    class="col-6 position-relative"
                    label="Meta Title"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      :maxlength="250"
                      v-model="form.meta_title"
                      placeholder="Enter Meta Title"
                    >
                    </b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.meta_title"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_title.length !== 250,
                          'badge-danger': form.meta_title.length === 250,
                        }"
                      >
                        You typed
                        {{ form.meta_title.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="col-6 position-relative"
                    label="Meta Keywords"
                    label-for="key"
                  >
                    <b-form-input
                      :maxlength="250"
                      id="key"
                      v-model="form.meta_keywords"
                      placeholder="Enter Meta Keywords"
                    >
                    </b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.meta_keywords"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_keywords.length !== 250,
                          'badge-danger': form.meta_keywords.length === 250,
                        }"
                      >
                        You typed
                        {{ form.meta_keywords.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                </div>

                <b-form-group
                  id="input-group-20"
                  label="Meta Description"
                  label-for="input-20"
                >
                  <b-form-textarea
                    id="input-20"
                    v-model="form.meta_desc"
                    placeholder="Enter Meta Description"
                    rows="3"
                  >
                  </b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox v-model="form.survey_fail"
                      >If Survey Show Failed
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import seriesMixin from "../../../mixins/ModuleJs/series";
import Multiselect from "vue-multiselect";
import { required, maxLength } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      submitted: false,
      store: false,
      title1: "Add Series",
      title2: "Edit Series",
      items: [
        {
          text: "Back",
          href: "/series",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, seriesMixin],
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
  },
  validations: {
    form: {
      title: { required, maxLength: maxLength(400) },
      description: { required },
      video_selected: { required },
      community_selected: { required },
      partner_id: { required },
      partner_division_id: { required },
    },
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid || this.$v.form.description.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      this.store = true;
      if (
        this.$v.form.video_selected.$invalid ||
        this.$v.form.community_selected.$invalid ||
        this.$v.form.partner_id.$invalid ||
        this.$v.form.partner_division_id.$invalid
      ) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    getFormTitle(id) {
      let res = this.forum.filter((f) => f.id == id);
      if (res.length > 0) {
        return res[0].title;
      }
      return null;
    },
  },
};
</script>
